import { TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ApiClient } from 'src/client/api-client';
import { SimpleModal } from '../SimpleModal';
import { Styles } from './styles';

type DeleteApplicationModalProps = {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    application: {
        name: string;
        id: string;
    };
};

export const DeleteApplicationModal: React.FC<DeleteApplicationModalProps> = (props) => {
    const [applicationNameInput, setApplicationNameInput] = useState('');
    const [deleteApplicationError, setDeleteApplicationError] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const deleteApplication = async (): Promise<void> => {
        const result = await ApiClient.deleteApplication(props.application.id);
        if (!result.success) {
            setDeleteApplicationError(result.error);
        } else {
            navigate('/applications');
        }
    };

    return (
        <SimpleModal
            modalTitle="Confirm Delete Application"
            isOpen={props.isOpen}
            setOpen={props.setOpen}
            submitActionButtonText="Delete Application"
            submitActionButtonOnClick={deleteApplication}
            submitActionButtonDisabled={applicationNameInput !== props.application.name}
            submitActionButtonStyles={Styles.deleteApplicationButton}
            submitActionButtonColor="error"
            submitActionButtonVariant="outlined"
            cancelActionButtonText="Cancel"
            cancelActionButtonOnClick={() => props.setOpen(false)}
        >
            <Typography>
                Are you absolutely sure? This action is <b>irreversable</b>! This will permanently delete the{' '}
                <b>{props.application.name}</b> application! The API key, client ID, secrets, organizations, associated
                locations, and all application data will be deleted.{' '}
            </Typography>
            <br />
            <Typography>
                This is a very destructive action. <b>Are you sure you want to delete this application?</b>
            </Typography>
            <br />
            <Typography>
                Please type <b>{props.application.name}</b> to confirm.
            </Typography>

            <TextField
                sx={Styles.applicationNameInput}
                size="small"
                value={applicationNameInput}
                onChange={(event) => setApplicationNameInput(event.target.value)}
            />
            {deleteApplicationError && (
                <Typography sx={Styles.deleteApplicationErrorMessage}>{deleteApplicationError}</Typography>
            )}
        </SimpleModal>
    );
};
