import { Box, Button, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { ApiClient } from 'src/client/api-client';
import { RequestHelperResult } from 'src/client/api-client/endpoints/endpoint-helpers';
import { GenericTextAndTitle } from 'src/client/components/GenericTextAndTitle';
import { UserAbilities } from 'src/client/types/user-abilities';
import { Clock } from 'src/shared/lib/clock';
import { DateTimeHelpers } from 'src/shared/lib/date-helpers';
import { ApplicationRole } from 'src/shared/types/developer-portal-roles';
import { GeneratePreviewApiKeyConfirmation } from '../GeneratePreviewApiKeyConfirmation';
import { ItemDetailsHeader } from '../ItemDetailsHeader';
import { ReadOnlyTextAndCopy } from '../ReadOnlyTextAndCopy';
import { ReadOnlyTextWithAction } from '../ReadOnlyTextWithAction';
import { RotateAppSecretConfirmationModal } from '../RotateAppSecretConfirmationModal';
import { SubmitButton } from '../SubmitButton';
import { TeamsList } from '../TeamsList';
import { DeleteApplicationModal } from './DeleteApplicationModal';
import { EntitlementWarningMessage } from './EntitlementWarningMessage';
import { Styles } from './styles';

const getApplicationCreationDetails = (createdBy?: { firstName?: string; lastName?: string }): string => {
    if (createdBy?.firstName && createdBy?.lastName) {
        return `created by ${createdBy.firstName} ${createdBy.lastName} on`;
    }
    return `created on`;
};

const getApplicationSubtitleDisplay = (teamCount: number): string => `${teamCount} team${teamCount > 1 ? 's' : ''}`;

type AppDetailsProps = {
    application: {
        id: string;
        name: string;
        apiKey: string;
        clientId: string;
        createdBy?: {
            firstName?: string;
            lastName?: string;
        };
        createdOn: string;
        teams: Array<{
            teamId: string;
            name: string;
            role: ApplicationRole;
            individualTeamUserId?: string;
        }>;
        secret1Expiry?: string | undefined;
        secret2Expiry?: string | undefined;
        legacyPartnerEmail?: string;
        previewApiKey?: string;
    };
    entitlement: {
        id: string;
        activationId?: string;
        applicationId: string;
        deviceLimit?: number;
        expirationDate?: string;
        claimedDevices: number;
        duration?: string;
    };
    userAbilities: UserAbilities;
    teamIdsForUser: string[];
    requery: () => void;
};

export const AppDetails: React.FC<AppDetailsProps> = (props) => {
    const theme = useTheme();
    const [rotateSecretInfo, setRotateSecretInfo] = useState<'secret1' | 'secret2' | undefined>();
    const [isUpdatingSubscriptionDetails, setIsUpdatingSubscriptionDetails] = useState<boolean>(false);
    const [updateSubscriptionError, setUpdateSubscriptionError] = useState<string | undefined>(undefined);
    const [entitlementDetails, setEntitlementDetails] = useState<AppDetailsProps['entitlement']>(props.entitlement);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const saveApplicationName = (name: string): Promise<RequestHelperResult<undefined>> =>
        ApiClient.updateApplicationInfo(props.application.id, name);

    const displayError = (secretExpiry: string | undefined): boolean =>
        !!secretExpiry && new Date(secretExpiry) < Clock.now();

    const updateEntitlementsHandler = async (): Promise<void> => {
        setIsUpdatingSubscriptionDetails(true);
        const result = await ApiClient.refreshEntitlementStatus(props.application.id);
        if (!result.success) {
            setUpdateSubscriptionError(result.error);
        } else {
            setEntitlementDetails(result.data);
        }
        setIsUpdatingSubscriptionDetails(false);
    };

    const onClickDelete = () => {
        setShowConfirmDeleteModal(true);
    };

    return (
        <Box sx={Styles.body} data-testid="application-details">
            <ItemDetailsHeader
                sx={Styles.header}
                title={props.application.name}
                subtitle={`${getApplicationSubtitleDisplay(props.application.teams.length)}`}
                details={`${getApplicationCreationDetails(props.application.createdBy)} ${DateTimeHelpers.formatDateStr(
                    props.application.createdOn
                )}`}
                onSave={saveApplicationName}
                userAbilities={props.userAbilities}
            />
            <Typography variant="h5" sx={Styles.sectionHeader}>
                Application Credentials
            </Typography>
            <Box sx={Styles.textBoxes(theme)}>
                <Box sx={Styles.details}>
                    <ReadOnlyTextAndCopy
                        sx={Styles.readOnlyText}
                        title="Client Id"
                        content={props.application.clientId}
                    />

                    <ReadOnlyTextAndCopy sx={Styles.readOnlyText} title="API Key" content={props.application.apiKey} />

                    {props.application.previewApiKey ? (
                        <ReadOnlyTextAndCopy
                            sx={Styles.readOnlyText}
                            title="Preview API Key"
                            helperText="Avoid using the Preview APIs in production applications."
                            content={props.application.previewApiKey}
                        />
                    ) : (
                        <GeneratePreviewApiKeyConfirmation
                            applicationId={props.application.id}
                            canEdit={props.userAbilities.includes('Edit')}
                            onSuccess={props.requery}
                        />
                    )}
                </Box>

                <Box sx={Styles.details}>
                    <ReadOnlyTextWithAction
                        data-testid={'secret-1-display'}
                        sx={Styles.readOnlyText}
                        title="Secret 1 Expiry"
                        content={props.application.secret1Expiry ?? ''}
                        buttonText="Regenerate"
                        buttonDisabled={!props.userAbilities.includes('Edit')}
                        displayError={displayError(props.application.secret1Expiry)}
                        helperText={
                            !props.application.secret1Expiry
                                ? 'Failed to load Secret 1'
                                : displayError(props.application.secret1Expiry)
                                ? 'Secret 1 is expired'
                                : undefined
                        }
                        onClick={(): void => setRotateSecretInfo('secret1')}
                    />
                    <ReadOnlyTextWithAction
                        data-testid={'secret-2-display'}
                        sx={Styles.readOnlyText}
                        title="Secret 2 Expiry"
                        content={props.application.secret2Expiry ?? ''}
                        buttonDisabled={!props.userAbilities.includes('Edit')}
                        displayError={displayError(props.application.secret2Expiry)}
                        buttonText="Regenerate"
                        helperText={
                            !props.application.secret2Expiry
                                ? 'Failed to load Secret 2'
                                : displayError(props.application.secret2Expiry)
                                ? 'Secret 2 is expired'
                                : undefined
                        }
                        onClick={(): void => setRotateSecretInfo('secret2')}
                    />
                </Box>
            </Box>
            {entitlementDetails.activationId && (
                <>
                    <Box sx={Styles.entitlementBox} data-testid="entitlement-box">
                        <Typography variant="h5" sx={Styles.sectionHeader}>
                            Subscription Details
                        </Typography>
                        <Box sx={Styles.entitlementDetails}>
                            <Box sx={Styles.entitlementDetail}>
                                <GenericTextAndTitle
                                    id="activation-key"
                                    title="Activation Key"
                                    content={entitlementDetails.activationId}
                                />
                            </Box>
                            <Box sx={Styles.entitlementDetail}>
                                <GenericTextAndTitle
                                    id="expiration-info"
                                    title="Expiration Date"
                                    content={
                                        entitlementDetails.expirationDate ??
                                        `${entitlementDetails.duration ?? ''} after first device commissioning`
                                    }
                                />
                            </Box>
                            <Box sx={Styles.entitlementDetail}>
                                <GenericTextAndTitle
                                    id="total-devices-info"
                                    title="Total no. of devices"
                                    content={entitlementDetails.deviceLimit?.toString() ?? 'N/A'}
                                />
                            </Box>
                            <Box sx={Styles.entitlementDetail}>
                                <GenericTextAndTitle
                                    id="claimed-device-info"
                                    title="No. of activated devices"
                                    content={entitlementDetails.claimedDevices.toString()}
                                />
                            </Box>
                        </Box>
                        <SubmitButton
                            buttonText="Refresh"
                            spinning={isUpdatingSubscriptionDetails}
                            onClick={() => {
                                void updateEntitlementsHandler();
                            }}
                        />
                        {updateSubscriptionError && <Typography>{updateSubscriptionError}</Typography>}
                    </Box>
                    <EntitlementWarningMessage
                        claimedDevices={entitlementDetails.claimedDevices}
                        deviceLimit={entitlementDetails.deviceLimit}
                        expirationDate={entitlementDetails.expirationDate}
                    />
                    <Box sx={Styles.renewSubscriptionText} data-testid="renew-subscription">
                        <Typography variant="h6">
                            <Link
                                href="https://www.eaton.com/us/en-us/digital/brightlayer-experience-hub-/digital_subscription_management_instructions.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                View Subscription Details & Renewal Instructions
                            </Link>
                        </Typography>
                    </Box>
                </>
            )}
            <TeamsList
                title="Teams Assigned to Your Application"
                sx={Styles.teamList}
                teams={props.application.teams}
                application={props.application}
                requeryAppDetails={props.requery}
                userAbilities={props.userAbilities}
                teamIdsForUser={props.teamIdsForUser}
            />
            {rotateSecretInfo && (
                <RotateAppSecretConfirmationModal
                    secretName={rotateSecretInfo}
                    app={{ id: props.application.id, name: props.application.name }}
                    onClose={(): void => setRotateSecretInfo(undefined)}
                    onSuccess={props.requery}
                />
            )}
            {props.userAbilities.includes('Delete') && (
                <Box>
                    <DeleteApplicationModal
                        isOpen={showConfirmDeleteModal}
                        setOpen={setShowConfirmDeleteModal}
                        application={props.application}
                    />
                    <Button
                        sx={Styles.deleteApplicationButton}
                        variant="outlined"
                        color="error"
                        size="large"
                        onClick={onClickDelete}
                    >
                        Delete Application
                    </Button>
                </Box>
            )}
        </Box>
    );
};
