import { Theme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';

export const Styles = {
    body: {
        display: 'flex',
        flexDirection: 'column',
        margin: '25px',
        marginTop: '25px',
    },
    readOnlyText: {
        marginBottom: '16px',
        marginRight: '50px',
        height: '80px',
    },
    teamList: {
        marginTop: '32px',
        marginBottom: '20px',
    },
    header: {
        marginBottom: '14px',
    },
    sectionHeader: {
        paddingBottom: 2,
    },
    textBoxes: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(1280)]: {
            flexDirection: 'column',
        },
    }),
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    entitlementBox: {
        paddingLeft: '10px',
        flexDirection: 'row',
    },
    warningBox: {
        marginTop: '10px',
    },
    entitlementDetails: {
        paddingLeft: '10px',
        display: 'flex',
        width: '70%',
        flexWrap: 'wrap',
        row: '150px',
    },
    entitlementDetail: {
        width: '50%',
    },
    entitlementUpdateBox: {
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    securityInfoBar: {
        marginBottom: '15px',
        backgroundColor: Colors.yellow[50],
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    renewSubscriptionText: {
        marginTop: '20px',
        paddingLeft: '10px',
        flexDirection: 'row',
    },
    applicationNameInput: {
        paddingTop: 1,
    },
    deleteApplicationButton: {
        maxWidth: '230px',
        textTransform: 'uppercase',
        borderColor: Colors.red[500],
    },
    deleteApplicationErrorMessage: {
        color: Colors.red[500],
        marginTop: 2,
    },
};
